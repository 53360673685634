import { useRouter } from "next/router";
import Image from "next/image";
import PaginationCustomAjax from "@/components/shared/pagination-custom-ajax";
import sharedService from "@/services/shared-service";
import { useState } from "react";
import { getGospel } from "@/services/home-service";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure,
    ScrollShadow, Button, Link} from "@nextui-org/react";
import { isEmpty } from "lodash";
import NotFound from "./not-found";

export default function ClergiesBlock({ clergies, isPagination, actions }) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [quotes, setQuotes] = useState(null);
    const router = useRouter();

    if (clergies.total === 0) {
        return (<div className="h-[200px] md:h-[300px] mt-2">
            <NotFound query={"trong danh sách Linh mục"} />
        </div>)


    }

    async function openCode(code) {
        let quotes = await getGospel(code);
        setQuotes(quotes);
        onOpen();
    }

    return <>
        {clergies.value?.map((clergyData) => {
            const isRIP = clergyData.status === "rip";
            const clergyName = (clergyData?.stName) ? clergyData?.stName + ' ' + clergyData?.name : '';

            return (
                <>
                    <div className="sm:flex border-b-2 border-primary p-5">
                        <div className="contain-img my-auto">
                            <Image
                                className="w-[50%] lg:!w-full mx-auto !relative object-contain !h-[unset] rounded-[10px]"
                                alt={clergyName}
                                width={120}
                                height={120}
                                src={
                                    sharedService.isValidUrl(clergyData?.photo)
                                        ? `${clergyData?.photo?.replace(
                                              "public/",
                                              ""
                                          )}`
                                        : clergyData?.photo?.replace(
                                              "public/",
                                              ""
                                          )
                                        ? `${
                                              GlobalSettings.Settings.Server
                                          }/${clergyData?.photo?.replace(
                                              "public/",
                                              ""
                                          )}`
                                        : "/assets/images/logo-for-web.png"
                                }
                            />
                        </div>
                        <div className="contain-infos ml-3 w-full pr-5">
                            <div className="info--title mt-3">
                                {clergyData?.stName && (
                                    <>
                                        <h4 className="font-bold text-blue-700 mb-3 text-xl">
                                            Linh Mục {clergyName}
                                        </h4>
                                    </>
                                )}

                                {clergyData?.parable && (
                                    <p className="mb-3">
                                        Châm ngôn:{" "}
                                        <span
                                            className="text-violet-500 cursor-pointer"
                                            onClick={() =>
                                                openCode(
                                                    clergyData?.parable
                                                        ?.split("(")?.[1]
                                                        ?.split(")")?.[0]
                                                        ?.trim()
                                                )
                                            }
                                        >
                                            {clergyData?.parable}
                                        </span>
                                    </p>
                                )}

                                <hr />
                            </div>
                            <div className="info-further grid grid-cols-2 mt-3">
                                <div className="col-span-2 lg:col-span-1">
                                    {clergyData?.birthDay && (
                                        <p className="mb-3">
                                            Năm sinh: {clergyData?.birthDay}
                                        </p>
                                    )}
                                    {clergyData?.diaconusDay && (
                                        <p className="mb-3">
                                            Truyền Chức Phó Tế:{" "}
                                            {clergyData?.diaconusDay}
                                        </p>
                                    )}
                                    {clergyData?.clergyDay && (
                                        <p className="mb-3">
                                            Truyền Chức Linh Mục:{" "}
                                            {clergyData?.clergyDay}
                                        </p>
                                    )}

                                    {/* Mobile layout: start */}
                                    <p className="mb-3 lg:hidden">
                                        {isRIP ? (
                                            <>
                                                <span>Tình trạng: Đã Mất</span>
                                            </>
                                        ) : (
                                            <>
                                                Chức vụ:{" "}
                                                <span
                                                    className={
                                                        clergyData?.positionName
                                                            ? ""
                                                            : "italic"
                                                    }
                                                >
                                                    {clergyData?.positionName ||
                                                        "Đang cập nhật"}
                                                </span>
                                            </>
                                        )}
                                    </p>
                                    {!isRIP && (
                                        <>
                                            <p className="lg:hidden">
                                                <Link
                                                    href={`/nha-tho/${clergyData?.workPlaceId}`}
                                                >
                                                    Nơi phục vụ:{" "}
                                                    {clergyData?.workPlace}
                                                </Link>
                                            </p>
                                        </>
                                    )}
                                    {/* Mobile layout: end */}
                                </div>

                                <div className="hidden lg:block">
                                    <p className="mb-3">
                                        {clergyData.status === "rip" ? (
                                            <span>Tình trạng: Đã Mất</span>
                                        ) : (
                                            <>
                                                Chức vụ:{" "}
                                                <span
                                                    className={
                                                        clergyData?.positionName
                                                            ? ""
                                                            : "italic"
                                                    }
                                                >
                                                    {clergyData?.positionName ||
                                                        "Đang cập nhật"}
                                                </span>
                                            </>
                                        )}
                                    </p>

                                    {!isRIP && <>
                                        <p>
                                            <Link
                                                href={`/nha-tho/${clergyData?.workPlaceId}`}
                                            >
                                                Nơi phục vụ: {clergyData?.workPlace}
                                            </Link>
                                        </p>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        })}

            <Modal className="p-10" size="xl" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Tin Mừng</ModalHeader>
                            <ScrollShadow hideScrollBar={true} className="w-full h-[32rem]">
                                <ModalBody className="inline">
                                    <>
                                        {
                                            !isEmpty(quotes) ? quotes?.map(q => {
                                                return (
                                                    <span key={q.sentenceNo} className="text-medium inline"><p
                                                        className="align-text-top italic text-[10px] inline">[{q.sentenceNo}]</p>{q.content}</span>
                                                );
                                            }) : "Không tìm thấy dữ liệu"
                                        }
                                    </>
                                </ModalBody>
                            </ScrollShadow>
                            <ModalFooter>
                                <Button variant="light" onPress={onClose}>
                                    Đóng
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

        {
            isPagination && clergies.pageData?.pageCount > 1 && <>
                <div className="flex justify-center p-5">
                    <PaginationCustomAjax asPath={router.asPath} pageData={clergies.pageData} actions={{setCurrentPage: actions.setCurrentPage}} />
                </div>
            </>
        }
    </>
}
